import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { OPPONENTS } from "../App";
import { MatchDetails } from "../components/MatchDetails/MatchDetails";
import { Sticky } from "../components/Sticky/Sticky";
import { useAppContext } from "../context/AppContext";

function SelectTeam() {
  const navigate = useNavigate();
  const { currentOpponentId, setCurrentOpponentId } = useAppContext();
  return (
    <div className="team-page-container">
      <Sticky className="ui-sticky-prev">
        <button
          type="button"
          className="cy-back button button--alt"
          onClick={() => {
            navigate("../");
          }}
        >
          back
        </button>
      </Sticky>
      <Sticky className="ui-sticky-next">
        <button
          type="button"
          className="cy-next-team button"
          onClick={() => {
            setCurrentOpponentId(
              currentOpponentId === "opponent_a" ? "opponent_b" : "opponent_a"
            );
          }}
        >
          next team
        </button>
      </Sticky>
      <div className="team-vs-opponent">
        <MatchDetails
          team={"lil' Puckers"}
          opponent={
            OPPONENTS.find(({ id }) => {
              return id === currentOpponentId;
            })?.teamName || ""
          }
        />
      </div>
    </div>
  );
}

export default SelectTeam;
