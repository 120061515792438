import {
  Route,
  BrowserRouter,
  Routes,
  useLocation,
  createBrowserRouter,
  useOutlet,
} from "react-router-dom";
import { createRef, useState } from "react";
import { ThreeD } from "./components/ThreeD/ThreeD";
import SelectTeam from "./routes/SelectTeam";
// @ts-ignore
import {
  TransitionGroup,
  CSSTransition,
  SwitchTransition,
} from "react-transition-group";

import "./App.scss";
import Home from "./routes/Home";
import { ROUTES } from ".";
import { AppContext } from "./context/AppContext";

export const PLAYER_KEYS = [
  "team-1-player-1",
  "team-1-player-2",
  "team-1-player-3",
  "team-1-player-4",
  // "team-2-player-1",
  // "team-2-player-2",
  // "team-2-player-3",
  // "team-2-player-4",
  // "team-3-player-1",
  // "team-3-player-2",
  // "team-3-player-3",
  // "team-3-player-4",
];

export const MODEL_BY_PLAYER_KEY: { [key: string]: string } = {
  // "team-1-player-1": "/CryptoNaut03.glb",
  "team-1-player-1": "/CryptoNaut03.glb",
  "team-1-player-2": "/MissMeow_Mesh.glb",
  "team-1-player-3": "/OddFrog_Mesh.glb",
  "team-1-player-4": "/CrabGuy_Mesh.glb",

  "team-2-player-1": "/GameBear_Mesh.glb",
  "team-2-player-2": "/LoveGhost_Mesh.glb",
  "team-2-player-3": "/PlayerOne_Mesh.glb",
  "team-2-player-4": "/WinterQueen_Mesh.glb",

  "team-3-player-1": "/CryptoNaut03.glb",
  "team-3-player-2": "/CryptoNaut03.glb",
  "team-3-player-3": "/CryptoNaut03.glb",
  "team-3-player-4": "/CryptoNaut03.glb",
};

export const OPPONENTS = [
  {
    id: "opponent_a",
    teamName: "FC Cringe",
    playerKeys: [
      "team-2-player-1",
      "team-2-player-2",
      "team-2-player-3",
      "team-2-player-4",
    ],
  },
  {
    id: "opponent_b",
    teamName: "Buzzin' Bobs",
    playerKeys: [
      "team-3-player-1",
      "team-3-player-2",
      "team-3-player-3",
      "team-3-player-4",
    ],
  },
];

export const Site = () => {
  const [currentOpponentId, setCurrentOpponentId] =
    useState<string>("opponent_a");
  const location = useLocation();
  const currentOutlet = useOutlet();
  const { nodeRef } =
    ROUTES.find((route) => route.path === location.pathname) ?? {};

  return (
    <div className="App">
      <AppContext.Provider value={{ currentOpponentId, setCurrentOpponentId }}>
        <div className="page-container">
          <SwitchTransition>
            <CSSTransition
              key={location.pathname}
              // @ts-ignore
              nodeRef={nodeRef}
              timeout={500}
              classNames="page"
              unmountOnExit
            >
              {(state) => (
                // @ts-ignore
                <div ref={nodeRef} className="page">
                  {currentOutlet}
                </div>
              )}
            </CSSTransition>
          </SwitchTransition>
          <ThreeD currentOpponentId={currentOpponentId} />
        </div>
      </AppContext.Provider>
    </div>
  );
};
