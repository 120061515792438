import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Group, MathUtils, Object3D } from "three";
import { clone } from "three/examples/jsm/utils/SkeletonUtils";

import { useGLTF } from "@react-three/drei";
import { useFrame, useGraph } from "@react-three/fiber";
import { AllStarModel } from "./AllStarModel";

type ModelProps = {
  modelPath: string;
  onSelect?: () => void;
  follow?: Object3D;
  buttonPosition: [number, number, number];
};

export const AllStar = ({ follow, modelPath, onSelect }: ModelProps) => {
  const containerRef = React.useRef<Group>(null);
  const animationGroupRef = React.useRef<Group>(null);
  const [currentModelPath, setCurrentModelPath] = useState<string>(modelPath);
  const [isAnimatingOut, setIsAnimatingOut] = useState<boolean>(false);

  useFrame(() => {
    if (!animationGroupRef.current) return;
    animationGroupRef.current.position.y = MathUtils.lerp(
      animationGroupRef.current.position.y,
      isAnimatingOut ? 20 : 0,
      0.05
    );
    animationGroupRef.current.rotation.y = MathUtils.lerp(
      animationGroupRef.current.rotation.y,
      isAnimatingOut ? 20 : 0,
      0.05
    );
  });

  useEffect(() => {
    if (modelPath !== currentModelPath) {
      setIsAnimatingOut(true);
    }
    const id = setTimeout(() => {
      // we have [X] seconds to do some animation
      if (modelPath !== currentModelPath) {
      }
      setIsAnimatingOut(false);
      setCurrentModelPath(modelPath);
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }, [currentModelPath, modelPath]);

  useFrame(() => {
    if (containerRef.current) {
      if (follow) {
        const { x, y, z } = follow.position;
        containerRef.current?.position.set(x, y, z);
      }
    }
  });
  return (
    <group ref={containerRef} castShadow dispose={null}>
      <Suspense fallback={null}>
        <group ref={animationGroupRef}>
          <AllStarModel onSelect={onSelect} modelPath={currentModelPath} />
        </group>
      </Suspense>
    </group>
  );
};
