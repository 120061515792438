import logo from "./aifa-logo.png";
type Props = { team: string; opponent: string };

export const MatchDetails = ({ team, opponent }: Props) => {
  return (
    <div className="match-details">
      <div className="match-details__grid">
        <div className="grid-item--logo">
          <img src={logo} alt="aifa logo" />
        </div>
        <div className="grid-item--team">{team}</div>
        <div className="grid-item--vs">vs</div>
        <div className="grid-item--opponent">{opponent}</div>
      </div>
    </div>
  );
};
