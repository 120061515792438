import { createContext, Dispatch, SetStateAction, useContext } from "react";

export type AppContextValue = {
  currentOpponentId: string;
  setCurrentOpponentId: Dispatch<SetStateAction<string>>;
};

export const AppContext = createContext<AppContextValue | undefined>(undefined);

export const useAppContext = (): AppContextValue => {
  const app = useContext(AppContext);
  if (!app) throw Error(`Context used outside of provider`);
  return app;
};
